<template>
  <div class="base-con">
    <title-con title="合格证" />
    <div
      :class="
        turning == 3 ? 'query-condition query-condition-j ' : 'query-condition'
      "
    >
      <div v-if="turning != 3" class="left">
        <div
          v-for="item in dateList"
          :key="item.id"
          @click="timeHand(item)"
          class="item"
        >
          <img
            v-if="item.check"
            :src="require('@/assets/images/selected.png')"
            alt=""
          />
          <img
            v-if="!item.check"
            :src="require('@/assets/images/notSelected.png')"
            alt=""
          />
          <span>{{ item.name }}</span>
        </div>
        <div v-if="type == '1'" class="time-con">
          <img :src="require('@/assets/images/timek.png')" alt="" />
          <!-- <a-month-picker
            :defaultValue="month"
            size="small"
            class="monthPic"
            placeholder=""
            @change="onTimeChange"
            slot="suffixIcon"
            format="MM"
            :allowClear="false"
            defaultPickerValue="MM"
          /> -->
          <div class="monthPic">
            <a-select
              dropdownClassName="yueaselect"
              :default-value="month"
              v-model="month"
              style="width: 77px"
              @change="handlemoonClick"
              size="small"
              :showArrow="false"
            >
              <a-select-option
                v-for="item in moonList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
          <span>月</span>
        </div>
        <div v-if="type == '0'" class="time-con">
          <img :src="require('@/assets/images/timek.png')" alt="" />
          <!-- <a-date-picker
            :defaultValue="month"
            size="small"
            class="monthPic"
            placeholder=""
            @change="onTimeChange"
            slot="suffixIcon"
            format="YYYY"
            :allowClear="false"
            defaultPickerValue="YYYY"
          /> -->
          <div class="monthPic">
            <a-select
              dropdownClassName="nianaselect"
              :default-value="month"
              v-model="year"
              style="width: 77px"
              @change="handleyearClick"
              size="small"
              :showArrow="false"
            >
              <a-select-option
                v-for="item in yearList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
          <span>年</span>
        </div>
      </div>
      <div class="ri">
        <a-radio-group :default-value="format" @change="radioChange">
          <a-radio-button value="a"> 列表 </a-radio-button>
          <a-radio-button value="b"> 统计 </a-radio-button>
        </a-radio-group>
      </div>
    </div>
    <div v-if="format == 'a'" class="main-list">
      <div v-if="list.length != 0">
        <div v-for="(item, idx) in list" :key="idx" class="item-con">
          <img :src="require('@/assets/images/lbbg.png')" alt="" />
          <div class="list-con">
            <div class="top-con">
              <span class="subject">{{ item.subjectName }}</span>
              <!-- <div class="ri">
                <p>第三方检测</p>
                <p>合格</p>
              </div> -->
            </div>
            <div class="mian-info">
              <div>
                <h1>产品名称：{{ item.productName }}</h1>
                <h1>开具重量：{{ item.weight }}</h1>
                <h1>开具时间：{{ item.printDate }}</h1>
                <h1>开具张数：{{ item.printCount }}</h1>
                <h1>合格证编号：{{ item.certificateCode }}</h1>
              </div>
              <div @click="codeImgHand(item)" class="img-con">
                <img :src="item.qrcode" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="list.length == 0">
        <a-empty :image="require('@/assets/images/wusj.png')" />
      </div>
    </div>
    <div v-if="format == 'b'" class="main-list">
      <div class="top-jiao">
        <img
          v-if="turning == 0"
          :src="require('@/assets/images/zwxz.png')"
          alt=""
        />
        <img
          v-if="turning == 2 || turning == 1 || turning == 3"
          @click="zChange"
          :src="require('@/assets/images/zxz.png')"
          alt=""
        />
        <div class="label">
          <img :src="require('@/assets/images/tjbg.png')" alt="" />
          <span>{{ titleName }}</span>
        </div>
        <img
          v-if="turning == 0 || turning == 1 || turning == 2"
          @click="ychanage"
          :src="require('@/assets/images/yxz.png')"
          alt=""
        />
        <img
          v-if="turning == 3"
          :src="require('@/assets/images/ywxz.png')"
          alt=""
        />
      </div>
      <div v-if="turning == 0" class="list-item">
        <div class="title-list">
          <span>地区</span>
          <span>主体数</span>
          <span>开具数据(张)</span>
          <span>开具重量(吨)</span>
        </div>
        <div v-if="patrolArea.length != 0">
          <div
            v-for="(item, idx) in patrolArea"
            :key="idx"
            :class="
              idx == patrolArea.length - 1 ? 'mian-list bgcon' : 'mian-list'
            "
          >
            <span>{{ item.regionName }}</span>
            <span>{{ item.subjectCount }}</span>
            <span>{{ item.printSCount }}</span>
            <span>{{ item.printWeightCount }}</span>
          </div>
        </div>
        <div class="empty-con" v-if="patrolArea.length == 0">
          <a-empty :image="require('@/assets/images/wusj.png')" />
        </div>
      </div>
      <div v-if="turning == 1" class="list-item">
        <div class="title-list">
          <span>地区</span>
          <span>第一季度</span>
          <span>第二季度</span>
          <span>第三季度</span>
          <span>第四季度</span>
          <span>合计</span>
        </div>
        <div v-if="patrolQuarter.length != 0">
          <div
            v-for="(item, idx) in patrolQuarter"
            :key="idx"
            :class="
              idx == patrolQuarter.length - 1 ? 'mian-list bgcon' : 'mian-list'
            "
          >
            <span>{{ item.regionName }}</span>
            <span>{{ item.firstQuarter }}</span>
            <span>{{ item.secondQuarter }}</span>
            <span>{{ item.thirdQuarter }}</span>
            <span>{{ item.fourthQuarter }}</span>
            <span>{{ item.subtotal }}</span>
          </div>
        </div>
        <div class="empty-con" v-if="patrolQuarter.length == 0">
          <a-empty :image="require('@/assets/images/wusj.png')" />
        </div>
      </div>
      <div v-if="turning == 2" class="list-item">
        <div class="title-list">
          <span>排名</span>
          <span>主体名称</span>
          <span>累计开具(张)</span>
        </div>
        <div v-if="patrolResult.length != 0">
          <div
            v-for="(item, idx) in patrolResult"
            :key="idx"
            :class="
              idx == patrolResult.length - 1 ? 'mian-list bgcon' : 'mian-list'
            "
          >
            <span>{{ idx + 1 }}</span>
            <span style="width: 250px !important; text-align: center">{{
              item.subjectName
            }}</span>
            <span>{{ item.printCount }}</span>
          </div>
        </div>
        <div class="empty-con" v-if="patrolResult.length == 0">
          <a-empty :image="require('@/assets/images/wusj.png')" />
        </div>
      </div>
      <div v-if="turning == 3" class="list-item turning-list">
        <div
          v-if="lastyearList.length != 0 && thisyearList.length != 0"
          style="width: 59%"
        >
          <div class="title-list-con">
            <span style="margin-right: -120px">{{ yaerstart }}</span>
          </div>
          <div class="title-list-com">
            <span style="margin-left: 20px">地区</span>
            <span style="margin-left: 40px">开具数据</span>
            <span style="margin-left: 20px">开具重量</span>
          </div>
          <div>
            <div
              v-for="(item, idx) in lastyearList"
              :key="idx"
              :class="
                idx == lastyearList.length - 1
                  ? 'mian-list niandu bgcon'
                  : 'niandu mian-list'
              "
            >
              <span>{{ item.regionName }}</span>
              <span>{{ item.printSCount }}</span>
              <span>{{ item.printWeightCount }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="lastyearList.length != 0 && thisyearList.length != 0"
          style="flex: 1"
        >
          <div class="title-list-con">
            <span>{{ yaerend }}</span>
          </div>
          <div class="title-list-com">
            <span>开具数据</span>
            <span>开具重量</span>
          </div>
          <div>
            <div
              v-for="(item, idx) in thisyearList"
              :key="idx"
              :class="
                idx == thisyearList.length - 1
                  ? 'mian-list niandu bgcon'
                  : 'niandu mian-list'
              "
            >
              <span>{{ item.printSCount }}</span>
              <span>{{ item.printWeightCount }}</span>
            </div>
          </div>
        </div>
        <!-- <div class="empty-con" v-if="patrolResult.length == 0">
          <a-empty :image="require('@/assets/images/wusj.png')" />
        </div> -->
      </div>
    </div>

    <div v-if="format == 'a'" class="pagination-con">
      <div v-if="totalRecords != 0" class="pagination-left">
        <span>总共{{ totalRecords }}条，每页显示</span>
        <h1 class="pageSize">10</h1>
        <span>条记录</span>
      </div>
      <a-pagination
        v-if="totalRecords != 0"
        :current="currentPage"
        :total="totalRecords"
        @change="handleTableChange"
        :loading="loading"
      />
    </div>
    <!-- 二维码弹框 -->
    <div v-if="show" @click="removeHand" class="maek-con">
      <div class="iframe-con">
        <iframe :src="codeUrl" style="width: 600px; height: 800px" />
      </div>
    </div>
  </div>
</template>

<script>
import Index from "./index";
export default Index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
<style>
.nianaselect {
  left: 110px !important;
  top: 950px !important;
}
.yueaselect {
  left: 110px !important;
  top: 950px !important;
}
</style>
