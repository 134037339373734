import http from "../http.js";

const api = {
  getList: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/verticalScreen/listCertificate`,
      data: params,
    });
  },
  // 合格证打印按主体统计
  patrolResult: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/verticalScreen/certificateSubjectStats`,
      data: params,
    });
  },
  //季度
  patrolQuarter: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/verticalScreen/certificateQuarterStats`,
      data: params,
    });
  },
  //地区
  patrolArea: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/verticalScreen/certificateAreaStats`,
      data: params,
    });
  },
};

export default api;
